import { Component, OnInit, OnDestroy } from '@angular/core';
import { Firestore, doc, onSnapshot, collection, query, deleteDoc } from '@angular/fire/firestore';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-digital-list',
  templateUrl: './digital-list.component.html',
  styleUrls: ['./digital-list.component.scss']
})
export class DigitalListComponent implements OnInit, OnDestroy {
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  digital_categories: any[];

  constructor(
    private afs: Firestore) {
  }

  ngOnInit() {
	onSnapshot(query(collection(this.afs, "digital_category")), queryRef => {
	  const needDestroy = this.digital_categories ? true : false;
	  this.digital_categories = queryRef.docs.map(doc => {
		const data: any = doc.data();
		data.id = doc.id;
		  return data;
	  });
	  if (needDestroy) {
		  // Destroy the table first
		  this.dtElement.dtInstance.then(dtInstance => {
			dtInstance.destroy();
			// Call the dtTrigger to re-render again
			this.dtTrigger.next(null);
		  });
	  } else {
		  // Call the dtTrigger to re-render again
		  this.dtTrigger.next(null);
	  }
	});
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  
  delete(item) {
	  Swal.fire({
		  title: "Delete Digital Category",
		  text: `Are you sure want to delete ${item.name}?`,
		  icon: "warning",
		  showCancelButton: true,
		  confirmButtonText: "Yes",
		  cancelButtonText: "No",
	  }).then(res => {
		  if (res.isConfirmed) {
			  deleteDoc(doc(this.afs, "digital_category/" + item.id));
		  }
	  });
  }
}
