import { Component, OnInit, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { Firestore, doc, onSnapshot, collection, query, deleteDoc, updateDoc } from '@angular/fire/firestore';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss']
})
export class ListUserComponent implements OnInit, OnDestroy {
  public userList: any[];
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  constructor(
    private afs: Firestore) {
  }

  ngOnInit(): void {
	onSnapshot(query(collection(this.afs, "users")), queryRef => {
	  const needDestroy = this.userList ? true : false;
	  this.userList = queryRef.docs.map(doc => {
		const data: any = doc.data();
		data.id = doc.id;
		  return data;
	  });
	  if (needDestroy) {
		  // Destroy the table first
		  this.dtElement.dtInstance.then(dtInstance => {
			dtInstance.destroy();
			// Call the dtTrigger to re-render again
			this.dtTrigger.next(null);
		  });
	  } else {
		  // Call the dtTrigger to re-render again
		  this.dtTrigger.next(null);
	  }
	});
  }
  
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  
  delete(item) {
	  Swal.fire({
		  title: "Delete User",
		  text: `Are you sure want to delete ${item.name}?`,
		  icon: "warning",
		  showCancelButton: true,
		  confirmButtonText: "Yes",
		  cancelButtonText: "No",
	  }).then(res => {
		  if (res.isConfirmed) {
			  deleteDoc(doc(this.afs, "user/" + item.id));
		  }
	  });
  }

  updateStatus(item: any, value: any) {
    updateDoc(doc(this.afs, "users/" + item), {
      active: value,
    }).then(() => {});
  }
}

