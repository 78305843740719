<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div *ngIf="invoice else empty" id="pdf_content" class="card">
                <div class="card-header d-sm-flex">
                    <h5>{{ invoice.name }}</h5>
                </div>
                <div class="card-body">
                    <div class="row" style="margin-bottom: 60px">
                    	<div class="col-sm-6 mt-auto">
                    		<div class="mb-4">
                    			<img src="assets/images/logo-msm.png" width="150px">
                    		</div>
                    		<div>
                    			<p class="mb-2" style="color: #000; font-size: 16px; font-weight: 550">{{setting.name}}({{setting.registrationNo}})</p>
                    			<p style="white-space: pre-wrap">{{setting.address}}</p>
                    		</div>
                    	</div>
                    	<div class="col-sm-6">
                    		<div class="text-end">
                    			<h2>INVOICE</h2>
                    			<p class="mb-4">{{ invoice.name }}</p>
                    			<h6>Balance Due</h6>
                    			<h4 style="font-weight: 550">{{ getBalance() | currency: 'MYR ' }}</h4>
                    		</div>
                    	</div>
                    </div>
                    <div class="row" style="margin-bottom: 30px">
                    	<div class="col-sm-8 mt-auto">
                    		<h4>Bill To:</h4>
                    		<p>{{ invoice.property }}</p>
                    	</div>
                    	<div class="col-sm-4">
                    		<div class="row">
                    			<div class="col-sm-5 text-end">
                    				<p>Invoice Date :</p>
                    				<p>Terms :</p>
                    				<p>Due Date :</p>
                    			</div>
                    			<div class="col-sm-7 text-end">
                    				<p>{{ invoice.invoiceDate ? (invoice.invoiceDate.toDate() | date: 'dd/MM/yyyy') : '-' }}</p>
                    				<p>{{ invoice.term ? invoice.term : '-' }}</p>
                    				<p>{{ invoice.issuedDate ? (invoice.issuedDate.toDate() | date: 'dd/MM/yyyy') : '-' }}</p>
                    			</div>
                    		</div>
                    	</div>
                    </div>
                    <div class="row" style="margin-bottom: 10px">
                    	<div class="col-sm-12">
	                        <div class="table-responsive">
	                            <table class="table">
	                                <thead>
	                                    <tr>
	                                        <th scope="col">#</th>
	                                        <th scope="col">Item & Description</th>
	                                        <th scope="col">Qty</th>
	                                        <th scope="col" class="text-end">Rate (MYR)</th>
	                                        <th scope="col" class="text-end">Amount (MYR)</th>
	                                    </tr>
	                                </thead>
	                                <tbody>
	                                    <tr *ngFor="let item of invoice.item; let i = index">
	                                        <td scope="col">{{ i + 1 }}</td>
	                                        <td scope="col">{{ item.name }}  ({{invoice.inDate.toDate() | date: 'dd/MM/yyyy'}} - {{invoice.outDate.toDate() | date: 'dd/MM/yyyy'}})</td>
	                                        <td scope="col">{{ item.quantity }}</td>
	                                        <td scope="col" class="text-end">{{ item.rate }}</td>
	                                        <td scope="col" class="text-end">{{ item.amount | currency:'':'' }}</td>
	                                    </tr>
	                                </tbody>
	                            </table>
	                        </div>
                    	</div>
                    </div>

                    <div class="row" style="margin-bottom: 60px">
                    	<div class="col-sm-4 ms-auto">
                    		<div class="row py-3">
                    			<div class="col-sm-5 text-end">
                    				<p>Sub Total</p>
                    			</div>
                    			<div class="col-sm-7 text-end">
                    				<p>{{ invoice.total | currency : '' : '' }}</p>
                    			</div>
                    		</div>
                    		<div class="row py-3">
                    			<div class="col-sm-5 text-end">
                    				<p style="color: #000; font-weight: 550;">Total</p>
                    			</div>
                    			<div class="col-sm-7 text-end">
                    				<p style="color: #000"><strong>{{ invoice.total | currency: 'MYR ' }}</strong></p>
                    			</div>
                    		</div>
                    		<div class="row py-3">
                    			<div class="col-sm-5 text-end">
                    				<p>Payment Made</p>
                    			</div>
                    			<div class="col-sm-7 text-end">
                    				<p style="color: red">{{ (invoice.paid || 0) | currency: '(-) ' : '' }}</p>
                    			</div>
                    		</div>
                    		<div class="row py-3" style="background: #d9d9d9;">
                    			<div class="col-sm-5 text-end">
                    				<p style="color: #000; font-weight: 550">Balance Due</p>
                    			</div>
                    			<div class="col-sm-7 text-end">
                    				<p style="color: #000; font-weight: 550">{{ getBalance() | currency: 'MYR ' }}</p>
                    			</div>
                    		</div>
                    	</div>
                    </div>

                    <div class="row" style="margin-bottom: 30px">
                    	<div class="col-sm-12">
                    		<h4>Terms and Conditions</h4>
                    		<p style="white-space: pre-wrap;">{{setting?.tnc}}</p>
                    	</div>
                    </div>
				</div>
            </div>
			<ng-template #empty>
				<div class="text-center text-danger">Missing or Invalid Invoice.</div>
			</ng-template>
        </div>
    </div>
	
	<hr *ngIf="invoice" />
	
	<div *ngIf="invoice" class="row" style="margin-bottom: 30px">
		<div class="col-sm-12">
			<div class="pull-right">
				<button [disabled]="exporting" type="button" class="btn btn-primary" type="button" (click)="exportPDF()">{{
					exporting ? "Exporting..." : "Export"
				}}</button>
			</div>
		</div>
	</div>
</div>