import { Component, OnInit, ChangeDetectorRef,  AfterViewInit, ViewChild } from '@angular/core';
import { NavService } from '../../service/nav.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import {  fadeIn } from 'ng-animate';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
  animations: [
    trigger('animateRoute', [transition('* => *', useAnimation(fadeIn, {
      // Set the duration to 5seconds and delay to 2 seconds
      //params: { timing: 3}
    }))])
  ]
})
export class ContentLayoutComponent implements OnInit {

  routerOutletState: any;
  public right_side_bar: boolean;
  public layoutType: string = 'RTL';
  public layoutClass: boolean = false;

  constructor(
    public navServices: NavService, 
    private cdr: ChangeDetectorRef) { }

  @ViewChild('routerOutlet') routerOutlet: RouterOutlet;

  ngAfterViewInit() {
    this.updateRouterOutletState();
  }

  updateRouterOutletState() {
    if (this.routerOutlet) {
      this.routerOutletState = this.routerOutlet.isActivated ? this.routerOutlet.activatedRoute : null;
      this.cdr.detectChanges();
    }
  }

  getRouterOutletState(outlet: RouterOutlet) {
    this.routerOutletState = outlet.isActivated ? outlet.activatedRoute : null;
    this.cdr.detectChanges();
  }

  public rightSidebar($event) {
    this.right_side_bar = $event
  }

  public clickRtl(val) {
    if (val === 'RTL') {
      document.body.className = 'rtl';
      this.layoutClass = true;
      this.layoutType = 'LTR';
    } else {
      document.body.className = '';
      this.layoutClass = false;
      this.layoutType = 'RTL';
    }
  }

  ngOnInit() { }


}
