import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbNavModule, ModalDismissReasons, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { Firestore, addDoc, doc, onSnapshot, collection, query, where, deleteDoc, updateDoc, increment, getDoc, orderBy, limit } from '@angular/fire/firestore';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { extendMoment } from 'moment-range';
const extendedMoment = extendMoment(moment);

import { HttpClient } from '@angular/common/http';


declare const $:any;
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {AuthService} from 'src/app/shared/service/auth.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponent {

  
	@ViewChild("content") modalContent: TemplateRef<any>;
	@ViewChild("create") modalAddContent: TemplateRef<any>;
	@ViewChild('invoiceListTable', {static: false}) invoiceListTable: any;
  

	selectedType: any = '2';
	months: string[] = [
		'January', 'February', 'March', 'April', 'May', 'June', 
		'July', 'August', 'September', 'October', 'November', 'December'
	  ];
	years: number[] = [];
	selectedMonth: string = '';
	selectedYear: number = 2024;

	now: any = new Date('2024-04-01');
	startDate: any = new Date(this.now.getFullYear(), this.now.getMonth() - 1, 1);
	endDate: any = new Date(this.now.getFullYear(), this.now.getMonth(), 0);

	uploading: boolean = false;
	selectAll: boolean = false;
	invoiceList: any[];
	keyword: any = 'name'
	user: any;
	loading: boolean = false;
	exportData: any[] = [];
	booking: any = {};
  	form: FormGroup = new FormGroup({
      id: new FormControl('', []),
      email: new FormControl(''),
      term: new FormControl('', [Validators.required]),
      invoiceDate: new FormControl(''),
      issuedDate: new FormControl(''),
      company: new FormControl(''),
      address: new FormControl(''),
      item: new FormArray([])
  });
  invoiceId: string;
  nextId: number;
  nextIdString: string;
  submitting: any = false;
  categoryList: any[]; 
  customerList: any[];

  propertyList: any[];
  exportList: any[] = [];
  excelData: any = [];
  
  constructor(
	private modal: NgbModal,
    private afs: Firestore,
	private authService : AuthService,
    private router: Router,
    private http: HttpClient,
    ) { 
		this.generateYears();
		this.selectedMonth = this.months[new Date().getMonth()];
	}

  ngOnInit() {
	this.updateDates();

	this.authService.userObs.subscribe(user => {
		this.user = user;
	});

	this.updateResult();

    onSnapshot(query(collection(this.afs, "category"), where("category", "==", "invoice")), queryRef => {
      this.categoryList = queryRef.docs.map(doc => {
		const data: any = doc.data();
		data.id = doc.id;
		  return data;
	  });
    });
    onSnapshot(collection(this.afs, "customer"), queryRef => {
      this.customerList = queryRef.docs.map(doc => {
		const data: any = doc.data();
		data.id = doc.id;
		  return data;
	  });
    });

	onSnapshot(collection(this.afs, "property"), queryRef => {
		this.propertyList = queryRef.docs.map(doc => {
		  const data: any = doc.data();
		  data.id = doc.id;
			return data;
		});
	  });

    onSnapshot(doc(this.afs, "setting/increment"), docRef => {
      this.nextId = (docRef.data().invoice || 0) + 1;
      this.nextIdString = "#INV-" + this.nextId.toString().padStart(6, "0");
    });
	
	  onSnapshot(query(collection(this.afs, "invoice"), orderBy("name", "asc")), queryRef => {
		const docs = queryRef.docs;
		for (let i = 0; i < docs.length; i++) {
			const data: any = docs[i].data();
			data.id = docs[i].id;

			const excelData : any = {
				"Invoice No.": data.name,
				"Unit Name": data.property,
				"Check In": moment(data.inDate.toDate()).format('YYYY-MM-DD'),
				"Check Out": moment(data.outDate.toDate()).format('YYYY-MM-DD'),
				"Description": data.source,
				"Booking No.": data.booking,
				"Duration": data.guest,
				"Day of Stay": data.day,
				"Amount": data.amount,
				"Cleaning Fees": data.cleanning,
				"Platform Charge": data.charge,
				"Total": data.total,
			}
			this.exportList.push(excelData)
		}
		this.exportList = this.exportList.sort((a: any, b: any) => parseInt(a["Unit Name"]) - parseInt(b["Unit Name"]));
	  })
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  onTypeChange(type: any){
	this.selectedType = type;
	if(this.selectedType === '1'){
		this.selectedMonth = '';
	}
	this.updateDates();
  }

  onYearChange(year: number): void {
    this.selectedYear = year;
	this.updateDates();
  }

  onMonthChange(month: string): void {
    this.selectedMonth = month;
	console.log("this.selectedMonth", this.selectedMonth)
	this.updateDates();
  }

  search() {
	this.updateResult();
  }

  private generateYears(): void {
    const currentYear = new Date().getFullYear();
    this.years = Array.from({ length: 4 }, (_, i) => currentYear - i);
  }

  private updateDates(){
	console.log("this.selectedMonth", this.selectedMonth)
	if (this.selectedType === '1') {
		this.startDate = new Date(this.selectedYear, 0, 1);
		this.endDate = new Date(this.selectedYear + 1, 0, 1);
	  }
	else if (this.selectedType === '2' && this.selectedMonth) {
		const monthIndex = this.months.indexOf(this.selectedMonth);
		this.startDate = new Date(this.selectedYear, monthIndex, 1);
		this.endDate = new Date(this.selectedYear, monthIndex + 1, 1);
	}
  }

  updateResult(){
	//onSnapshot(query(collection(this.afs, "invoice"), orderBy('name', 'desc')), queryRef => {
		onSnapshot(query(collection(this.afs, "invoice"), where('issuedDate', '>=', this.startDate), where('issuedDate', '<=', this.endDate),orderBy('issuedDate'), orderBy('name', 'desc')), queryRef => {
		this.invoiceList = queryRef.docs.map(doc => {
			const data: any = doc.data();
			data.id = doc.id;
			data.selected = false;
			return data;
		});
		
		console.log("this.invoiceList", this.invoiceList)
		$(this.invoiceListTable.nativeElement).dataTable().fnDestroy();
		  setTimeout(()=>this.ngAfterViewInit(), 100)
	})
  }
  ngAfterViewInit() {
    this.invoiceListInit()
  }
      
  invoiceListInit(): void {
    $(this.invoiceListTable.nativeElement).dataTable({
      "bDestroy": true,
	  "columnDefs": [
		{
		//   "targets": [0, -1],
		//   "orderable": false // Disable sorting
		}
	  ]
    } );
  }
  
  

  createInvoice(){
	this.invoiceId = this.nextIdString;
    const array = this.form.get('item') as FormArray;
	array.clear();
	this.addContent();
    this.modal.open(this.modalAddContent, { size: 'lg' , centered: true });
  }

  validSubmit(form: FormGroup, dismissCallback: any) {
    console.log("form", form)
	form.markAllAsTouched();
	if (!form.valid) {
		return;
	}
	if (this.submitting) {
		return;
	}
	this.submitting = true;
    const data = {...this.form.value};
	data.amount = this.getTotal();
	let promise;
	if (data.id) {
		updateDoc(doc(this.afs, "invoice/" + data.id), data)
		.then(() => {
			Swal.fire({
			  title: 'Success!',
			  text: 'Invoice has been updated',
			  icon: "success",
			}).then(()=>{
			  this.submitting = false;
			  dismissCallback();
			})
		});
	} else {
		data.name = this.nextIdString;
		addDoc(collection(this.afs, "invoice"), data)
		.then(() => {
			Swal.fire({
			  title: 'Success!',
			  text: 'Invoice has been created',
			  icon: "success",
			}).then(()=>{
			  updateDoc(doc(this.afs, "setting/increment"), {
				  invoice: this.nextId
			  });
			  this.submitting = false;
			  dismissCallback();
			})
		});
	}
  }

  editInvoice(item: any) {
	this.invoiceId = item.invoice;
    const array = this.form.get('item') as FormArray;
	array.clear();
	for (let i = 0; i < item.item.length; i++) {
		this.addContent();
	}
	this.form.patchValue(item);
    this.modal.open(this.modalAddContent, { size: 'lg' , centered: true });
  }
  
  deleteInvoice(item: any) {
	  Swal.fire({
		  title: "Delete Invoice",
		  text: `Are you sure want to delete ${item.name}?`,
		  icon: "warning",
		  showCancelButton: true,
		  confirmButtonText: "Yes",
		  cancelButtonText: "No",
	  }).then(res => {
		  if (res.isConfirmed) {
			  deleteDoc(doc(this.afs, "invoice/" + item.id));
		  }
	  });
  }

  addContent() {
    const array = this.form.get('item') as FormArray;
    array.push(new FormGroup({
		name: new FormControl('', [Validators.required]),
		quantity: new FormControl('', [Validators.required]),
		rate: new FormControl('', [Validators.required]),
		amount: new FormControl('', [Validators.required])
	}));
  }
  
  getTotal() {
    let array = this.form.get('item') as FormArray;
	let total = 0;
	for (let control of array.controls) {
		const result = (control.get("rate").value || 0) * (control.get("quantity").value || 0);
		total += isNaN(result) ? 0 : result;
	}
	return parseFloat(total.toString());
  }

    selectEvent(item) {
      // do something with selected item
    }

    onChangeSearch(search: string) {
      // fetch remote data from here
      // And reassign the 'data' which is binded to 'data' property.
    }

    onFocused(e) {
      // do something
    }

	exportExcel() {
		if (this.exportList.length > 0) {
		  import("xlsx").then(xlsx => {
			const worksheet = xlsx.utils.json_to_sheet(this.exportList);
			const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
			const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
			this.saveAsExcelFile(excelBuffer, "ExportExcel");
		  });
		}
	  }
	
	  saveAsExcelFile(buffer: any, fileName: string): void {
		let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		let EXCEL_EXTENSION = '.xlsx';
		const data: Blob = new Blob([buffer], {
		  type: EXCEL_TYPE
		});
		FileSaver.saveAs(data, "Invoice Listing");
	  }
	
	  openImport(modal:any) {
		this.modal.open(modal, { centered: true, backdrop: 'static', keyboard: false});
	  }
	
	  async onChangeFile(event: any) {
		Swal.fire({
			title: 'Uploading...',
			text: 'Please wait while the file is being processed.',
			allowOutsideClick: false,
			didOpen: () => {
			  Swal.showLoading();
			}
		});
	
		const target: DataTransfer = <DataTransfer>(event.target);
		if (target.files.length !== 1) {
		  Swal.close();
		  throw new Error('Cannot use multiple files');
		}
	
		const reader: FileReader = new FileReader();
		reader.readAsBinaryString(target.files[0]);
		reader.onload = async (e: any) => {
		  try {
			const binarystr: string = e.target.result;
			const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
	
			const wsname: string = wb.SheetNames[0];
			const ws: XLSX.WorkSheet = wb.Sheets[wsname];
	
			let header: any = [];
			let data: any = "";
	
			header = [
			  'property',
			  'inDate',
			  'outDate',
			  'source',
			  'refNo',
			  'guest',
			  'day',
			  'amount',
			  'cleanning',
			  'charge',
			  'total',
			];
			data = XLSX.utils.sheet_to_json(ws, { raw: false, header: header, range: 1 });
	
			const docRef = doc(this.afs, "setting/increment");
			const incrementRef = await getDoc(docRef);
	
			let nextId = (incrementRef.data()?.invoice || 0) + 1;
	
			this.excelData = data;
			for (const data of this.excelData) {
			  data.item = [data.item];
	
			  data.item = data.item.map((item: any) => ({
				name: data.source,
				quantity: 1,
				amount: parseFloat(data.total.toString()),
				rate: parseFloat(data.total.toString()),
			  }));
			  data.name = "#INV-" + nextId.toString().padStart(6, "0");
	
			  await updateDoc(docRef, {
				invoice: increment(1),
			  });
	
			  nextId++;
			  
			  data.source = data.source.toLowerCase();
			  data.propertyId = this.getPropertyId(data.property);
			  data.term = "Due on Receipt";
			  data.invoiceDate = data.outDate;
			  data.refNo = data.refNo ? data.refNo : "";
			  data.guest = data.guest ? data.guest : "0";
			  data.day = data.day ? data.day : "0";
			  data.issuedDate = data.outDate;
			  data.amount = parseFloat(data.amount.toString());
			  data.cleanning = parseFloat(data.cleanning.toString());
			  data.charge = parseFloat(data.charge.toString());
			  data.total = parseFloat(data.total.toString());
			  data.created_at = new Date();
			  data.created_by = this.user.name;
			}
			console.log("this.excelData", this.excelData);
		  } catch (error) {
			console.error(error);
		  } finally {
			Swal.close();
		  }
		};
	  }
	
	  importCSV() {
			this.uploading = true;
			if(this.uploading){
				Swal.fire({
					title: 'Uploading...',
					text: 'Please wait while the file is being processed.',
					allowOutsideClick: false,
					didOpen: () => {
					  Swal.showLoading();
					}
				});
			}

			this.http.post('https://us-central1-ms-pro-management.cloudfunctions.net/invoiceUpload', this.excelData).toPromise()
			.then((res: any) => {
				console.log("invoice upload done...")
					this.uploading = false;
				Swal.fire({
					title: 'Success!',
					text: 'Invoice has been created!',
					icon: "success",
				}).then(()=>{
					window.location.reload()
				});
			})
		}
	
	  closeImport() {
		this.excelData = null;
		this.modal.dismissAll();
	  }

	  getPropertyId(id: any){
		const item = this.propertyList?.find((x: any) => x.name == id);
    	return item?.id;
	  }

	  convertToTimeZone(dateString: any, hour: any, minute: any, second: any, timeZone: any) {
		const date = new Date(dateString);
		date.setHours(hour, minute, second);
		const options: any = {
			timeZone: timeZone,
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
			hour12: true
		};
		return new Intl.DateTimeFormat('en-US', options).format(date);
	}

	detail(id: any){
		onSnapshot(doc(this.afs, "booking/" + id), queryRef => {
			const data = queryRef.data();
			this.booking = data;
		});
		this.modal.open(this.modalContent, { size: 'lg' , centered: true });
	}


    toggleSelectAll() {
        this.invoiceList.forEach(item => {
            item.selected = this.selectAll;
        });
    }

	deleteSelectedInvoices() {
		const selectedItems = this.invoiceList.filter(item => item.selected);
		if (selectedItems.length === 0) {
			Swal.fire({
				title: "No Selection",
				text: "Please select at least one invoice to delete.",
				icon: "warning",
				confirmButtonText: "OK"
			});
			return;
		}
		
	
		Swal.fire({
			title: "Delete Selected Invoices",
			text: `Are you sure you want to delete ${selectedItems.length} selected invoice(s)?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No",
		}).then(res => {
			if (res.isConfirmed) {
				selectedItems.forEach(item => {
					deleteDoc(doc(this.afs, "invoice/" + item.id));
				});
				Swal.fire({
					title: 'Success!',
					text: `${selectedItems.length} selected invoice(s) hav been removed!`,
					icon: "success",
				}).then(()=>{
					this.invoiceList = this.invoiceList.filter(item => !item.selected);
				})
			}
		});
	}

	async generateBooking(item:any){

		console.log("item", item)
		const inDate = new Date(item.inDate.toDate());
		const outDate = new Date(item.outDate.toDate());

		// Set times
		inDate.setHours(15, 0, 0, 0);
		outDate.setHours(12, 0, 0, 0);


		const booking = {
			charge: item.charge,
			cleanning: item.cleanning,
			created_at: new Date(),
			customer: "",
			refNo: item.refNo ? item.refNo : "",
			day: item.day,
			guest: item.guest,
			inDate: inDate,
			outDate: outDate,
			propertyId: this.getPropertyId(item.property),
			source: item.source.toLowerCase(),
			status: "pending",
			title: item.property,
			invoice_no: item.id,
			amount: item.amount,
			total: item.total,
		  };

		  console.log("booking", booking)
		  const docRef = await addDoc(collection(this.afs, "booking"), booking);
		  const bookingId = docRef.id;

		  console.log("bookingId", bookingId)

		  updateDoc(doc(this.afs, "invoice/"+ item.id), {
			booking: bookingId
		  }).then(()=>{
			Swal.fire({
				title: 'Success!',
				text: 'Booking has been created!',
				icon: "success",
			})
		  });
		  

	}

}
