<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-3 mb-4">
            <label for="type">Type</label>
            <ng-select
            (change)="onTypeChange($event)" 
            [(ngModel)]="selectedType" 
            [clearable]="false">
                <ng-option value="1">Yearly</ng-option>
                <ng-option value="2">Monthly</ng-option>
            </ng-select>
        </div>

        <div class="col-sm-3" *ngIf="selectedType === '2'">
            <label for="monthSelect">Select Month:</label>
            <ng-select
                id="monthSelect"
                [(ngModel)]="selectedMonth"
                (change)="onMonthChange($event)"
                [items]="months"
                [clearable]="false"
                placeholder="Select month"></ng-select>
        </div>
        <div class="col-sm-3">
            <label for="yearSelect">Select Year:</label>
            <ng-select
                id="yearSelect"
                [(ngModel)]="selectedYear"
                (ngModelChange)="onYearChange($event)"
                [clearable]="false">
                <ng-option *ngFor="let year of years" [value]="year">{{ year }}</ng-option>
            </ng-select>
        </div>
        <!-- <div class="col-sm-3 mb-4">
            <label class="form-label" for="startDate">
                Start Date
            </label>
            <input
                class="form-control"
                type="date"
                id="startDate"
                placeholder="Start Date"
                [(ngModel)]="firstDate">
        </div>
        <div class="col-sm-3 mb-4">
            <label class="form-label" for="endDate">
                End Date
            </label>
            <input
                class="form-control"
                type="date"
                id="endDate"
                placeholder="End Date"
                [(ngModel)]="lastDate">
        </div> -->
        <div class="col-sm-3 mb-4 d-flex align-items-end">
            <button
                class="btn btn-primary"
                (click)="search()">
                Search
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header d-sm-flex">
                    <h5>Invoice List</h5>
                    <a href="javascript:void(0)" class="btn btn-outline-primary ms-auto me-2 mb-2" (click)="openImport(import)">Import</a>
                    <a href="javascript:void(0)" class="btn btn-outline-primary me-2 mb-2" (click)="exportExcel()">Export</a>
                    <a href="javascript:void(0)" (click)="createInvoice()" class="btn btn-primary me-2 mb-2">Create</a>
                    <!-- <a (click)="deleteSelectedInvoices()" class="btn btn-primary">Bulk Delete</a> -->
                    <ng-template #create let-c="close" let-d="dismiss">
                        <div class="modal-header">
                            <h4 class="modal-title">Create Invoice</h4>
                            <button
                                type="button"
                                class="btn btn-close"
                                aria-label="Close"
                                (click)="d('Cross click')"></button>
                        </div>
                        <div class="modal-body">
                            <form
                                [formGroup]="form"
                                class="needs-validation user-add"
                                novalidate=""
                                (ngSubmit)="validSubmit(form, d)">
                                <div class="mb-4">
                                    <h5>{{ invoiceId }}</h5>
                                    <div class="row">
                                        <div *ngIf="form.controls['email']; let control" class="col-sm-12 mb-4">
                                            <label class="form-label" for="email">Recipient Email
                                                <span class="text-danger">*</span>
                                            </label>
                                            <ng-select
                                                [clearable]="false"
                                                placeholder="Select an item"
                                                formControlName="email">
                                                <ng-option value="{{customer.email}}" *ngFor="let customer of customerList">{{ customer.email }}</ng-option>
                                            </ng-select>
                                            <div class="invalid-feedback">Please enter your Recipient Email!</div>
                                        </div>
                                        <div *ngIf="form.controls['term']; let control" class="col-sm-12 mb-4">
                                            <label class="form-label" for="name">Terms
                                                <span class="text-danger">*</span>
                                            </label>
                                            <ng-select
                                                [clearable]="false"
                                                placeholder="Select an owner"
                                                formControlName="term">
                                                <ng-option value="Due on Receipt">Due on Receipt</ng-option>
                                            </ng-select>
                                            <div class="invalid-feedback">Please enter your terms!</div>
                                        </div>
                                        <div *ngIf="form.controls['invoiceDate']; let control" class="col-sm-6 mb-4">
                                            <label class="form-label" for="invoiceDate">Invoice Date
                                                <span class="text-danger">*</span>
                                            </label>
                                            <input
                                                class="form-control"
                                                type="date"
                                                id="invoiceDate"
                                                placeholder="Invoice Date"
                                                formControlName="invoiceDate"
                                                [ngClass]="{'is-invalid': control.touched && control.invalid}">
                                                <div class="invalid-feedback">Please enter your Invoice Date!</div>
                                            </div>
                                            <div *ngIf="form.controls['issuedDate']; let control" class="col-sm-6 mb-4">
                                                <label class="form-label" for="issuedDate">Issued Date
                                                    <span class="text-danger">*</span>
                                                </label>
                                                <input
                                                    class="form-control"
                                                    type="date"
                                                    id="issuedDate"
                                                    placeholder="Issued Date"
                                                    formControlName="issuedDate"
                                                    [ngClass]="{'is-invalid': control.touched && control.invalid}">
                                                    <div class="invalid-feedback">Please enter your Issued Date!</div>
                                                </div>
                                                <div *ngIf="form.controls['company']; let control" class="col-sm-12 mb-4">
                                                    <label class="form-label" for="issuedDate">Billing Company Name
                                                        <span class="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        class="form-control"
                                                        type="text"
                                                        id="company"
                                                        placeholder="Billing Company Name"
                                                        formControlName="company"
                                                        [ngClass]="{'is-invalid': control.touched && control.invalid}">
                                                        <div class="invalid-feedback">Please enter your billing company name!</div>
                                                    </div>
                                                    <div *ngIf="form.controls['address']; let control" class="col-sm-12 mb-4">
                                                        <label class="form-label" for="address">Billing Address
                                                            <span class="text-danger">*</span>
                                                        </label>
                                                        <textarea
                                                            class="form-control"
                                                            type="text"
                                                            id="address"
                                                            placeholder="Billing Address"
                                                            formControlName="address"
                                                            [ngClass]="{'is-invalid': control.touched && control.invalid}"
                                                            rows="5"></textarea>
                                                        <div class="invalid-feedback">Please enter your billing address!</div>
                                                    </div>
                                                    <div class="col-sm-12 mb-4">
                                                        <h5>Invoice Item</h5>
                                                        <div class="row">
                                                            <div class="col-sm-7">
                                                                <label>Item</label>
                                                            </div>
                                                            <div class="col-sm-1">
                                                                <label>Qty</label>
                                                            </div>
                                                            <div class="col-sm-2">
                                                                <label>Rate</label>
                                                            </div>
                                                            <div class="col-sm-2 text-end">
                                                                <label>Amount</label>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="form.controls['item']; let array" class="row" formArrayName="item">
                                                            <ng-container *ngFor="let control of array.controls" [formGroup]="control">
                                                                <div class="col-sm-7 mb-4">
                                                                    <input formControlName="name" class="form-control"/>
                                                                    <div class="invalid-feedback">Please seleclt Category!</div>
                                                                </div>
                                                                <div class="col-sm-1 mb-4">
                                                                    <input formControlName="quantity" class="form-control"/>
                                                                    <div class="invalid-feedback">Please enter Qty!</div>
                                                                </div>
                                                                <div class="col-sm-2 mb-4">
                                                                    <input formControlName="rate" class="form-control"/>
                                                                    <div class="invalid-feedback">Please enter Rate!</div>
                                                                </div>
                                                                <div class="col-sm-2 mb-4">
                                                                    <input formControlName="amount" class="form-control"/>
                                                                    <div class="invalid-feedback">Please enter Amount!</div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm-4 ms-auto">
                                                                <a class="btn btn-secondary" style="float: right;" (click)="addContent()">+ Add Item</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr>
                                                <div class="row">
                                                    <div class="col-sm-5">
                                                        <div class="d-flex align-items-center">
                                                            <app-feather-icons [icon]="'dollar-sign'" class="font-secondary me-3"></app-feather-icons>
                                                            <div>
                                                                <label>
                                                                    <strong>Total Amount (RM)</strong>
                                                                </label>
                                                                <h6>{{ getTotal() | currency:'':''}}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-7">
                                                        <button type="submit" class="btn btn-primary text-center float-end">
                                                            <span>Submit</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </ng-template>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive deleted-table">
                                        <table #invoiceListTable class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <!-- <th class="text-center">
                                                    <div class="form-check">
                                                        <input
                                                            class="form-check-input"
                                                            type="checkbox"
                                                            [(ngModel)]="selectAll"
                                                            (change)="toggleSelectAll()"
                                                            style="width: 20px; height: 20px"></div>

                                                    </th> -->
                                                    <th>Invoice No.</th>
                                                    <th>Booking No.</th>
                                                    <th>Property</th>
                                                    <th>Invoice Date</th>
                                                    <th>Amount (RM)</th>
                                                    <th>Payment Status</th>
                                                    <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of invoiceList">
                                                            <!-- <th class="text-center">
                                                                <div class="form-check">
                                                                    <input
                                                                        class="form-check-input"
                                                                        type="checkbox"
                                                                        [(ngModel)]="item.selected"
                                                                        id="checkInvoice"
                                                                        style="width: 20px; height: 20px"></div>
                                                            </th> -->
                                                            <th>{{ item.name }}</th>
                                                            <td>
                                                                <a href="javascript:void(0)" (click)="detail(item.booking)">{{ item.booking }}</a>
                                                            </td>
                                                            <td>{{ item.property }}</td>
                                                            <td>{{ item.issuedDate ? (item.issuedDate.toDate() | date: 'dd/MM/yyyy') : '-' }}</td>
                                                            <td>{{ item.total | currency: '' : '' }}</td>
                                                            <td>
                                                                <span class='badge badge-secondary'>Bank Transfer</span>
                                                            </td>
                                                            <td>
                                                                <a href="invoice/view;id={{item.id}}" title="view">
                                                                    <app-feather-icons [icon]="'eye'" class="font-primary"></app-feather-icons>
                                                                </a>&nbsp;&nbsp;
                                                                <a (click)="editInvoice(item)" title="Edit">
                                                                    <app-feather-icons [icon]="'edit'" class="font-secondary"></app-feather-icons>
                                                                </a>&nbsp;
                                                                <a (click)="generateBooking(item)" title="GenerateBooking" *ngIf="!item.booking">
                                                                    <app-feather-icons [icon]="'book-open'" class="font-info"></app-feather-icons>
                                                                </a>&nbsp;
                                                                <!-- <a (click)="deleteInvoice(item)" title="Delete">
                                                                    <app-feather-icons [icon]="'trash-2'" class="font-danger"></app-feather-icons>
                                                                </a> -->
                                                            </td>
                                                        </tr>
                                                </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #import let-modal>
                                    <div class="modal-header">
                                        <h6 class="modal-title" id="modal-basic-title">Import CSV</h6>
                                        <button type="button" class="close" aria-label="Close" (click)="closeImport()">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <form >
                                            <div class="row mb-3">
                                                <div class="form-group col-12">
                                                    <input
                                                        type="file"
                                                        name="import"
                                                        id="import"
                                                        class="form-control"
                                                        (change)="onChangeFile($event)"
                                                        accept=".csv, .xlsx"
                                                        required></div>
                                                </div>
                                                <div class="row">
                                                    <div class="form-group col-12 text-center">
                                                        <button type="button" class="btn btn-success" (click)="importCSV()">Import</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </ng-template>

                                    <ng-template #content let-c="close" let-d="dismiss">
                                        <div class="modal-header">
                                            <h4 class="modal-title">{{ booking.title }}</h4>
                                            <div [ngSwitch]="booking.source" class="ms-auto">
                                                <img *ngSwitchCase="'mspro'" src="assets/images/logo-msm.png" width="80px">
                                                    <img *ngSwitchCase="'airbnb'" src="assets/images/airbnb-logo.png" width="80px">
                                                        <img
                                                            *ngSwitchCase="'booking'"
                                                            src="assets/images/booking-logo.png"
                                                            width="80px">
                                                            <img *ngSwitchCase="'agoda'" src="assets/images/agoda-logo.png" width="80px">
                                                                <h2 *ngSwitchCase="'cash'">Cash</h2>
                                                                <h2 *ngSwitchCase="'extend'">Extend</h2>
                                                                <img *ngSwitchDefault src="assets/images/logo-msm.png" width="80px"></div>
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-close ms-2"
                                                                    aria-label="Close"
                                                                    (click)="d('Cross click')"></button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <div class="row">
                                                                    <div class="col-sm-4">
                                                                        <div class="d-flex align-items-center" style="border-right: 1px solid #dee2e6">
                                                                            <app-feather-icons [icon]="'users'" class="font-secondary me-3"></app-feather-icons>
                                                                            <div>
                                                                                <label>Guests</label>
                                                                                <h6>{{ booking.guest }}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-4 ms-auto">
                                                                        <div class="d-flex align-items-center">
                                                                            <app-feather-icons [icon]="'dollar-sign'" class="font-secondary me-3"></app-feather-icons>
                                                                            <div>
                                                                                <label>Status</label>
                                                                                <div [ngSwitch]="booking.status">
                                                                                    <h6 *ngSwitchCase="'pending'">
                                                                                        <span class="badge badge-secondary">Pending</span>
                                                                                    </h6>
                                                                                    <h6 *ngSwitchCase="'paid'">
                                                                                        <span class="badge badge-success">Paid</span>
                                                                                    </h6>
                                                                                    <h6 *ngSwitchCase="'cancelled'">
                                                                                        <span class="badge badge-success">Cancelled</span>
                                                                                    </h6>
                                                                                    <h6 *ngSwitchDefault>
                                                                                        <span class="badge badge-secondary">Pending</span>
                                                                                    </h6>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                                    <div class="row">
                                                                        <div class="col-sm-6">
                                                                            <div class="book-time">
                                                                                <label>Check In</label>
                                                                                <h6>{{ booking.inDate.toDate() | date: 'EEE, d MMM, y' }}</h6>
                                                                                <p>{{ booking.inDate.toDate() | date: 'shortTime' }}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div class="book-time">
                                                                                <label>Check In</label>
                                                                                <h6>{{ booking.outDate.toDate() | date: 'EEEE, MMMM d, y' }}</h6>
                                                                                <p>{{ booking.outDate.toDate() | date: 'shortTime' }}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr>
                                                                        <div class="row">

                                                                            <div class="col-sm-5">
                                                                                <div class="d-flex align-items-center">
                                                                                    <app-feather-icons [icon]="'users'" class="font-secondary me-3"></app-feather-icons>
                                                                                    <div>
                                                                                        <label>Total Cost (RM)</label>
                                                                                        <h6>{{booking.amount | currency:'':''}}</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-7">
                                                                                <div class="btn btn-primary text-center  float-end">
                                                                                    <app-feather-icons [icon]="'download'" class="font-white me-3"></app-feather-icons>
                                                                                    <span style="position: relative; top: -4px">Download Invoice</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>
                                                            </div>
                                                            <!-- Container-fluid Ends-->