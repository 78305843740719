<div class="page-main-header" [class.open]="open">
    <div class="main-header-right row">
        <div class="main-header-left d-lg-none col-9">
            <h2 class="logo-title"><a [routerLink]="'/dashboard/default'">Admin Dashboard</a></h2>
        </div>
        <div class="mobile-sidebar col-3">
            <div class="media-body switch-sm sidebar-icon">
                <label class="switch">
                    <a>
                        <app-feather-icons id="sidebar-toggle" [icon]="'align-left'" (click)="collapseSidebar()">
                        </app-feather-icons>
                    </a>
                </label>
            </div>
        </div>
        <!-- <div class="nav-right col">
            <ul class="nav-menus" [ngClass]="{'open' : openNav}">
                <li class="onhover-dropdown">
                    <div class="media align-items-center"><div
                            class="align-self-center pull-right img-50 rounded-circle profile-img"
                            src="assets/images/dashboard/man.png" alt="header-user">{{user.name.charAt(0).toUpperCase()}}</div>
                    </div>
                    <ul class="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                        <li><a [routerLink]="'/settings/profile'">
                                <app-feather-icons [icon]="'user'"></app-feather-icons>Edit Profile
                            </a></li>
                        <li>
                            <a [routerLink]="'/auth/login'" (click)="logout()">
                                <app-feather-icons [icon]="'log-out'"></app-feather-icons>Logout
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
                <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
            </div>
        </div> -->
    </div>
</div>