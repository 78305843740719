import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: '/dashboard', title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Property', icon: 'home', type: 'sub', active: false, children: [
				{ path: '/property/property-list', title: 'Property List', type: 'link' },
				{ path: '/property/category', title: 'Category', type: 'link' },
				{ path: '/property/statement', title: 'Statement', type: 'link' },
			]
		},
		{
			title: 'Investor', icon: 'user', type: 'sub', active: false, children: [
				{ path: '/investor/investor-list', title: 'Investor List', type: 'link' },
				{ path: '/investor/create-investor', title: 'Create Investor', type: 'link' },
			]
		},
		{
			title: 'Calendar', icon: 'calendar', type: 'sub', active: false, children: [
				{ path: '/calendar/booking-list', title: 'Booking List', type: 'link' },
			]
		},
		{
			title: 'Documentations', icon: 'file-text', type: 'sub', active: false, children: [
				{ path: '/invoice', title: 'Invoice', type: 'link' },
				{ path: '/expenses', title: 'Expenses', type: 'link' },
			]
		},
		{
			title: 'User Management', icon: 'users', type: 'sub', active: false, children: [
				{ path: '/users/user-list', title: 'Staff', type: 'link' },
			]
		},
		{
			title: 'Settings', path: '/settings/profile', icon: 'settings', type: 'link', active: false
		},
		{
			title: 'Renovation', icon: 'file-text', type: 'sub', active: false, children: [
				{ path: '/renovation/invoice', title: 'Invoice', type: 'link' },
				{ path: '/renovation/expenses', title: 'Expenses', type: 'link' },
			]
		},

	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
