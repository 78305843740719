import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

  active:any = 1;
  form: FormGroup;
  submitting: boolean = false;

  constructor(
    private authService: AuthService
  ) {
    
  }


  ngOnInit() {
    this.resetForm();
  }

  private resetForm(){
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      role: new FormControl('superadmin', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
    })
  }
  
  validSubmit(form: FormGroup){
    form.markAllAsTouched();
    if (!form.valid) {
      return;
    }
    if (this.submitting) {
      return;
    }

    this.submitting = true;	
    const data = {...this.form.value};
    data.created_at = new Date();
    data.active = true;

    this.authService.signUp(data)
    .then((res: any) => {
      Swal.fire("Registration", "You had successfully registered.", "success")
      .then(() => {
        this.submitting = false;
        this.resetForm();
      });
    }, (msg: any) => {
      this.submitting = false;
      Swal.fire("Registration", msg, "error");
    });
  }
}