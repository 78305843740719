<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12">
            <div class="card tab2-card">
                <div class="card-body">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <i class="me-2" data-feather="user"></i>Profile</a>
                            <ng-template ngbNavContent>
                                <div
                                    class="tab-pane fade show active"
                                    id="top-profile"
                                    role="tabpanel"
                                    aria-labelledby="top-profile-tab">
                                    <h5>Profile</h5>
                                    <div>
                                        <form
                                            [formGroup]="form"
                                            class="needs-validation user-add"
                                            novalidate=""
                                            (ngSubmit)="validSubmit(form)">
                                            <div class="mb-4">
                                                <div class="row">
                                                    <div *ngIf="form.controls['name']; let control" class="col-sm-12 mb-4">
                                                        <label class="form-label" for="name">Company Name
                                                            <span class="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            class="form-control"
                                                            type="text"
                                                            id="name"
                                                            placeholder="Company Name"
                                                            formControlName="name"
                                                            [ngClass]="{'is-invalid': control.touched && control.invalid}">
                                                            <div class="invalid-feedback">Please enter your company name!</div>
                                                        </div>
                                                        <div *ngIf="form.controls['registrationNo']; let control" class="col-sm-4 mb-4">
                                                            <label class="form-label" for="registrationNo">Company Registration No.
                                                                <span class="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                class="form-control"
                                                                type="text"
                                                                id="registrationNo"
                                                                placeholder="Company Registration No."
                                                                formControlName="registrationNo"
                                                                [ngClass]="{'is-invalid': control.touched && control.invalid}">
                                                                <div class="invalid-feedback">Please enter your Company Registration No.!</div>
                                                            </div>
                                                            <div *ngIf="form.controls['email']; let control" class="col-sm-4 mb-4">
                                                                <label class="form-label" for="email">Company Email Address<span class="text-danger">*</span>
                                                                </label>
                                                                <input
                                                                    class="form-control"
                                                                    type="email"
                                                                    id="email"
                                                                    placeholder="Company Email Address"
                                                                    formControlName="email"
                                                                    [ngClass]="{'is-invalid': control.touched && control.invalid}">
                                                                    <div class="invalid-feedback">Please enter your company email address!</div>
                                                                </div>
                                                                <div *ngIf="form.controls['contact']; let control" class="col-sm-4 mb-4">
                                                                    <label class="form-label" for="contact">Contact No.<span class="text-danger">*</span>
                                                                    </label>
                                                                    <input
                                                                        class="form-control"
                                                                        type="text"
                                                                        id="contact"
                                                                        placeholder="Company Contact No."
                                                                        formControlName="contact"
                                                                        [ngClass]="{'is-invalid': control.touched && control.invalid}">
                                                                        <div class="invalid-feedback">Please enter your company contact no.!</div>
                                                                    </div>
                                                                    <div *ngIf="form.controls['address']; let control" class="col-sm-12 mb-4">
                                                                        <label class="form-label" for="address">Company Address
                                                                            <span class="text-danger">*</span>
                                                                        </label>
                                                                        <textarea
                                                                            class="form-control"
                                                                            placeholder="Company Address"
                                                                            formControlName="address"
                                                                            [ngClass]="{'is-invalid': control.touched && control.invalid}"
                                                                            rows="5"></textarea>
                                                                        <div class="invalid-feedback">Please enter your company address!</div>
                                                                    </div>
                                                                    <div *ngIf="form.controls['tnc']; let control" class="col-sm-12 mb-4">
                                                                        <label class="form-label" for="tnc">Terms and Conditions
                                                                            <span class="text-danger">*</span>
                                                                        </label>
                                                                        <textarea
                                                                            class="form-control"
                                                                            placeholder="Terms and Conditions"
                                                                            formControlName="tnc"
                                                                            [ngClass]="{'is-invalid': control.touched && control.invalid}"
                                                                            rows="5"></textarea>
                                                                        <div class="invalid-feedback">Please enter your terms and conditions!</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                        <div class="pull-right">
                                                            <button
                                                                type="button"
                                                                class="btn btn-primary"
                                                                type="submit"
                                                                (click)="validSubmit(form)">{{submitting ? "Submitting" : "Submit"}}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </li>
                                        <li [ngbNavItem]="2">
                                            <a ngbNavLink class="d-flex align-items-center">
                                                <i data-feather="settings" class="me-2"></i>Category</a>
                                            <ng-template ngbNavContent>

                                                <div class=" d-sm-flex">
                                                    <h5>Category List</h5>
                                                    <a
                                                        href="success:void(0)"
                                                        class="btn btn-primary ms-auto" style="height: 40px"
                                                        (click)="createCategory()">Create category</a>
                                                </div>
                                                <ng-template #create let-c="close" let-d="dismiss">
                                                    <div class="modal-header">
                                                        <h4 class="modal-title">Create Category</h4>
                                                        <button
                                                            type="button"
                                                            class="btn btn-close"
                                                            aria-label="Close"
                                                            (click)="d('Cross click')"></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <form
                                                            [formGroup]="categoryForm"
                                                            class="needs-validation user-add"
                                                            novalidate=""
                                                            (ngSubmit)="validSubmitCategoryForm(categoryForm)">
                                                            <div class="mb-4">
                                                                <div class="row">
                                                                    <div *ngIf="categoryForm.controls['name']; let control" class="col-sm-12 mb-4">
                                                                        <label class="form-label" for="name">Category Name
                                                                            <span class="text-danger">*</span>
                                                                        </label>
                                                                        <input
                                                                            class="form-control"
                                                                            type="text"
                                                                            id="name"
                                                                            placeholder="Category Name"
                                                                            formControlName="name"
                                                                            [ngClass]="{'is-invalid': control.touched && control.invalid}">
                                                                            <div class="invalid-feedback">Please enter your category name!</div>
                                                                        </div>
                                                                        <div *ngIf="categoryForm.controls['price']; let control" class="col-sm-6 mb-4">
                                                                            <label class="form-label" for="price">Price (RM)
                                                                                <span class="text-danger">*</span>
                                                                            </label>
                                                                            <input
                                                                                class="form-control"
                                                                                type="text"
                                                                                id="price"
                                                                                placeholder="Price (RM)"
                                                                                formControlName="price"
                                                                                [ngClass]="{'is-invalid': control.touched && control.invalid}">
                                                                                <div class="invalid-feedback">Please enter your price!</div>
                                                                            </div>
                                                                            <div
                                                                                *ngIf="categoryForm.controls['category']; let control"
                                                                                class="col-sm-6 mb-4">
                                                                                <label class="form-label" for="price">Category
                                                                                    <span class="text-danger">*</span>
                                                                                </label>
                                                                                <ng-select
                                                                                    [clearable]="false"
                                                                                    placeholder="Select an owner"
                                                                                    formControlName="category">
                                                                                    <ng-option value="invoice">Invoice</ng-option>
                                                                                    <ng-option value="expenses">Expenses (Bill to Investor)</ng-option>
                                                                                </ng-select>
                                                                                <div class="invalid-feedback">Please enter your category!</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr>
                                                                        <div class="row">
                                                                            <div class="col-sm-4 ms-auto">
                                                                                <button type="submit" class="btn btn-primary text-center float-end">
                                                                                    <span>{{submitting ? "Submitting..." : "Submit"}}</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </ng-template>
                                                            <div class="table-responsive">
                                                                <table class="table table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" sortable="name">Category Name</th>
                                                                            <th scope="col" sortable="amount">Category</th>
                                                                            <th scope="col" sortable="amount">Pricing (RM)</th>
                                                                            <th>Actions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <ng-container *ngFor="let item of categoryList">
                                                                            <tr>
                                                                                <td>{{item.name}}</td>
                                                                                <td>{{item.category}}</td>
                                                                                <td>{{item.price | currency:'':'' }}</td>
                                                                                <td>
                                                                                    <a href="success:void(0)" title="Edit">
                                                                                        <app-feather-icons [icon]="'edit'" class="font-secondary"></app-feather-icons>
                                                                                    </a>&nbsp;
                                                                                    <a href="javascript:void(0)" title="Delete">
                                                                                        <app-feather-icons [icon]="'trash-2'" class="font-danger"></app-feather-icons>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-container>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </ng-template>
                                                    </li>
                                                </ul>
                                                <div [ngbNavOutlet]="nav" class="mt-2"></div>

                                                <!-- <ngb-tabset class="tab-coupon profile-tabs">
                        <ngb-tab>
                            <ng-template ngbTabTitle>
                                <app-feather-icons [icon]="'user'"></app-feather-icons>Profile</ng-template>
                            <ng-template ngbTabContent>

                            </ng-template>
                        </ngb-tab>
                        <ngb-tab>
                            <ng-template ngbTabTitle>
                                <i data-feather="settings"></i> Contact</ng-template>
                            <ng-template ngbTabContent>
                               
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Container-fluid Ends-->