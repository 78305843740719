import { Injectable, EventEmitter } from '@angular/core';
import { Auth, authState, sendPasswordResetEmail, confirmPasswordReset, signInWithEmailAndPassword
	, signOut, createUserWithEmailAndPassword, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from '@angular/fire/auth';
import { Firestore, doc, getDoc, onSnapshot, addDoc, collection, setDoc, where } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  fieldName = "users";
  collectionName = "users";
  dbUser: any;
  userObs: ReplaySubject<any>;

  private upgradeSubscriber: any;
  private userSubscriber: any;

  constructor(
    private afAuth: Auth,
    private router: Router,
    private afs: Firestore,
  ) {  

   this.userObs = new ReplaySubject(1);
    authState(this.afAuth).subscribe(user=> {
      if (user) {
      // User is exist
      
      if(user.email) {
        
        localStorage.setItem('email', user.email)
        onSnapshot(this.getMyDocRef(user.uid), (docRef: any) => {
          const dbUser = docRef.data();
          if(dbUser) {
            this.dbUser = dbUser;
            this.userObs.next(dbUser);
            localStorage.setItem('account_address', user.uid);
          } else {
            this.dbUser = null;
            this.userObs.next(null);          
            // observer.error('You are not ' + this.collectionName + '.');
          }
        });
      } else {
          this.dbUser = null;
          this.userObs.next(null);
        }
      } else {
      // User too long no activity, reset token
      this.dbUser = null;
      this.userObs.next(null);
      // observer.error('Your token is expired, please relog.');
      }
    });
  
  this.userObs.subscribe();
  }
  
  getMyDocRef(key: any) {
    return doc(this.afs, this.collectionName + "/" + key);
  }

  get isLoggedIn(): boolean {
      return this.dbUser !== null;
  }
  
  login(email: any, password: any){
    const promise = new Promise((resolve, reject) => {
      return signInWithEmailAndPassword(this.afAuth, email, password).then((res: any) => {
        if (res.user) {
    return getDoc(this.getMyDocRef(res.user.uid))
      .then((docRef) => {
        const dbUser = docRef.data();
        if (dbUser && dbUser.active == true) {
          console.log("user", dbUser)
          localStorage.setItem('name', dbUser?.username)
          localStorage.setItem('email', dbUser?.email)
          this.userObs.next(dbUser);
          resolve(dbUser);
        } else {
          reject('Account is deactivated. Please contact administrator.');
        }
      },  err  => {
        reject(this.parse(err.code));
      });
        } else {
          reject('Unregistered.');
        }
      },  err => {
        reject(this.parse(err.code));
      });
    });

    return promise;
}

  logout() {
    localStorage.clear();
    return signOut(this.afAuth);
  }


  sendResetPassword(email: string) {
  return sendPasswordResetEmail(this.afAuth, email, { 
    url: 'https://smapcrms.com',
    handleCodeInApp: false
  });
  }

  resetPassword(code: any, password: any) {
    return confirmPasswordReset(this.afAuth, code, password);
  }
  
  signUp(dbUser: any) {
  const promise = new Promise((resolve, reject) => {
    return createUserWithEmailAndPassword(this.afAuth, dbUser.email, dbUser.password).then((res) => {
      if (res.user) {
        delete dbUser.password;
        delete dbUser.confirmPassword;
        return setDoc(doc(this.afs, "users/" + res.user.uid), dbUser)
        .then(() => {
          this.userObs.next(dbUser);
          resolve(dbUser);
        }, err => {
          reject(this.parse(err.code));
        });
      } else {
        reject("User missing");
      }
    }, err => {
     reject(this.parse(err.code));
    });
    });

    return promise;
  }


  parse(errorCode: string): string {
    let message: string;
    switch (errorCode) {
      case 'auth/invalid-email':
      case 'auth/wrong-password':
        message = 'Invalid login credentials.';
        break;
      case 'auth/network-request-failed':
        message = 'Please check your internet connection';
        break;
      case 'auth/too-many-requests':
        message =
          'We have detected too many requests from your device. Take a break please!';
        break;
      case 'auth/user-disabled':
        message =
          'Your account has been disabled or deleted. Please contact the system administrator.';
        break;
      case 'auth/requires-recent-login':
        message = 'Please login again and try again!';
        break;
      case 'auth/email-already-exists':
        message = 'This email address is registered by other, if it is you, try to login instead with register.';
        break;    
      case 'auth/email-already-in-use':
        message = 'This email address is registered by other, if it is you, try to login instead with register.';
        break;
      case 'auth/user-not-found':
        message =
          'We could not find user account associated with the email address or phone number.';
        break;
      case 'auth/phone-number-already-exists':
        message = 'The phone number is already in use by an existing user.';
        break;
      case 'auth/invalid-phone-number':
        message = 'The phone number is not a valid phone number!';
        break;
      case 'auth/invalid-email':
        message = 'The email address is not a valid email address!';
        break;
      case 'auth/cannot-delete-own-user-account':
        message = 'You cannot delete your own user account.';
        break;    
    case 'auth/code-expired':
        message = 'The OTP is expired!';
        break;
    case 'auth/invalid-verification-code':
      message = 'The OTP is invalid!';
        break;
      case 'auth/too-many-requests':
      message = 'Too many attempts, try again later or change another phone number!';
        break;
      default:
        message = 'Oops! Something went wrong. Try again later.';
        break;
    }

    return message;
  }
}