<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-sm-8 mx-auto">
                    <div class="card-body">
                        <div class="text-center">
                            <img src="assets/images/logo-msm.png" class="logo-img">
                        </div>
                        <form [formGroup]="form" class="form-horizontal auth-form" novalidate="" (ngSubmit)="validSubmit(form)">
                            <div class="form-group" *ngIf="form.controls['email']; let control">
                                <label>Email Address</label>
                                <input required="" name="email" type="email"
                                    class="form-control" placeholder="Email Address" autocomplete="off" formControlName="email" [ngClass]="{'is-invalid': control.touched && control.invalid}">
                                    <div class="invalid-feedback">Please enter your email!</div>
                            </div>
                            <div class="form-group" *ngIf="form.controls['password']; let control">
                                <label>Password</label>
                                <input required="" name="password" type="password"
                                    class="form-control" placeholder="Password" autocomplete="off" formControlName="password" [ngClass]="{'is-invalid': control.touched && control.invalid}">
                                    <div class="invalid-feedback">Please enter your paswword!</div>
                            </div>
                            <div class="form-terms">
                                <div class="custom-control custom-checkbox d-flex align-items-center flex-wrap">
                                    <a href="javascript:void(0)"
                                        class="btn btn-default forgot-pass ms-auto">Forgot password</a>
                                </div>
                            </div>
                            <div class="form-button text-center">
                                <button class="btn btn-primary" type="submit"[disabled]="submitting">{{ submitting ? 'Processing...': 'Login' }}</button>
                            </div>
                        </form>        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>