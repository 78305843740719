import { Component, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { NgbNavModule, ModalDismissReasons, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Firestore, increment, addDoc, doc, onSnapshot, collection, query, where, deleteDoc, limit, updateDoc, getDocs, getDoc, FieldValue  } from '@angular/fire/firestore';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public active = 1;
  submitting: any = false;
  form!: FormGroup;
  categoryList: any = [];

  categoryForm!: FormGroup;

  profile: any;

  @ViewChild("create") modalContent: TemplateRef<any>;

  constructor(
    private modal: NgbModal,
    private afs: Firestore,
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      registrationNo: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      contact: new FormControl('', [Validators.required]),
      tnc: new FormControl('', [Validators.required]),
    })

    onSnapshot(doc(this.afs, "setting/profile" ), queryRef => {
      this.profile = queryRef.data();
      if (this.profile) {
        this.form.patchValue(this.profile);
      }
    })


    this.categoryForm = new FormGroup({
      name:  new FormControl('', [Validators.required]),
      price:  new FormControl('', [Validators.required]),
      category:  new FormControl('', [Validators.required]),
    })

    onSnapshot(collection(this.afs, "category"), queryRef => {
      const docs = queryRef.docs;
      this.categoryList = [];
        for (let i = 0; i < docs.length; i++) {
        const data: any = docs[i].data();
        data.uid = docs[i].id;
        this.categoryList.push(data);
      }
    });
  }  

  validSubmit(form: any){
    form.markAllAsTouched();
    if (!form.valid) {
      return;
    }

    if(this.submitting) {
      return;
    }
    this.submitting = true;
    
    const data = {...this.form.value};
    data.created_at = new Date();

    updateDoc(doc(this.afs, "setting/profile"), data)
    .then((res) => {
      Swal.fire({
      title: 'Success!',
      text: 'Company has been updated!',
      icon: "success",
      }).then((res) => {
          this.submitting = false;
        });
     });
  }

  validSubmitCategoryForm(form: any){
    form.markAllAsTouched();
    if (!form.valid) {
      return;
    }

    if(this.submitting) {
      return;
    }
    this.submitting = true;
    
    const data = {...this.categoryForm.value};
    data.created_at = new Date();

    addDoc(collection(this.afs, "category"), data)
    .then((res) => {
      Swal.fire({
      title: 'Success!',
      text: 'Category has been created!',
      icon: "success",
      }).then((res) => {
          this.submitting = false;
          this.categoryForm.reset();
        });
     });
  }

  createCategory(){
    this.modal.open(this.modalContent, { size: 'lg' , centered: true });
  }
}
