<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header d-sm-flex">
            <h5>User Details</h5>
            <a class="btn btn-primary ms-auto" [routerLink]="'/users/create-user'">Create User</a>
		</div>
        <div class="card-body">
			<div class="table-responsive">
				<table datatable [dtTrigger]="dtTrigger" class="table table-striped">
					<thead>
						<tr>
							<th>Username</th>
							<th>Email</th>
							<th>Role</th>
							<th>Status</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of userList">
							<td>
								{{item.name}}
							</td>
							<td>{{item.email}}</td>
							<td>{{item.role}}</td>
							<td>
								<label class="switch">
									<input
										type="checkbox"
										[(ngModel)]="item.active"
										(ngModelChange)="updateStatus(item.id, $event)"/>
									<span class="slider round"></span>
								</label>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->