import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'products',
    loadChildren: () => import('../../components/products/products.module').then(m => m.ProductsModule),
    data: {
      breadcrumb: "Products"
    }
  },
  {
    path: 'sales',
    loadChildren: () => import('../../components/sales/sales.module').then(m => m.SalesModule),
    data: {
      breadcrumb: "Sales"
    }
  },
  {
    path: 'coupons',
    loadChildren: () => import('../../components/coupons/coupons.module').then(m => m.CouponsModule),
    data: {
      breadcrumb: "Coupons"
    }
  },
  {
    path: 'pages',
    loadChildren: () => import('../../components/pages/pages.module').then(m => m.PagesModule),
    data: {
      breadcrumb: "Pages"
    }
  },
  {
    path: 'media',
    loadChildren: () => import('../../components/media/media.module').then(m => m.MediaModule),
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: "Users"
    }
  },
  {
    path: 'vendors',
    loadChildren: () => import('../../components/vendors/vendors.module').then(m => m.VendorsModule),
    data: {
      breadcrumb: "Vendors"
    }
  },
  {
    path: 'localization',
    loadChildren: () => import('../../components/localization/localization.module').then(m => m.LocalizationModule),
    data: {
      breadcrumb: "Localization"
    }
  },
  {
    path: 'reports',
    loadChildren: () => import('../../components/reports/reports.module').then(m => m.ReportsModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('../../components/setting/setting.module').then(m => m.SettingModule),
    data: {
      breadcrumb: "Settings"
    }
  },
  {
    path: 'invoice',
    loadChildren: () => import('../../components/invoice/invoice.module').then(m => m.InvoiceModule),
    data: {
      breadcrumb: "Invoice"
    }
  },
  {
    path: 'expenses',
    loadChildren: () => import('../../components/expenses/expenses.module').then(m => m.ExpensesModule),
    data: {
      breadcrumb: "Expenses"
    }
  },
  {
    path: 'property',
    loadChildren: () => import('../../components/property/property.module').then(m => m.PropertyModule),
    data: {
      breadcrumb: "Property"
    }
  },
  {
    path: 'calendar',
    loadChildren: () => import('../../components/calendar/calendar.module').then(m => m.CalendarModule),
    data: {
      breadcrumb: "Calendar"
    }
  },
  {
    path: 'investor',
    loadChildren: () => import('../../components/owner/owner.module').then(m => m.OwnerModule),
    data: {
      breadcrumb: "Investor"
    }
  },
  {
    path: 'renovation',
    loadChildren: () => import('../../components/renovation/renovation.module').then(m => m.RenovationModule),
    data: {
      breadcrumb: "Renovation"
    }
  },
];