import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataTablesModule } from 'angular-datatables';

import { InvoiceRoutingModule } from './invoice-routing.module';
import { InvoiceComponent } from './invoice.component';
import { ViewComponent } from './view/view.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

@NgModule({
  declarations: [InvoiceComponent, ViewComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    InvoiceRoutingModule,
    SharedModule,
    FormsModule, 
    ReactiveFormsModule,
    NgbModule,
	DataTablesModule,
	AutocompleteLibModule
  ]
})
export class InvoiceModule { }
