import { Component, OnInit } from '@angular/core';
import * as chartData from '../../shared/data/chart';
import { doughnutData, pieData } from '../../shared/data/chart';
import { Firestore, doc, onSnapshot, collection, query, deleteDoc, orderBy, addDoc, where } from '@angular/fire/firestore';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
  
export class DashboardComponent implements OnInit {
  public doughnutData = doughnutData;
  public pieData = pieData;
  propertyList: any[] = [];
  investorList: any[] = [];
  constructor(
    private afs: Firestore,
  ) {
    this.generateYears();
    this.selectedMonth = this.months[new Date().getMonth()];
    Object.assign(this, { doughnutData, pieData });
  }

  
  public doughnutChartColorScheme = chartData.doughnutChartcolorScheme;
  public doughnutChartShowLabels = chartData.doughnutChartShowLabels;
  public doughnutChartGradient = chartData.doughnutChartGradient;
  public doughnutChartTooltip = chartData.doughnutChartTooltip;

  public chart5 = chartData.chart5;

  public lineChartData = chartData.lineChartData;
  public lineChartLabels = chartData.lineChartLabels;
  public lineChartOptions = chartData.lineChartOptions;
  public lineChartColors = chartData.lineChartColors;
  public lineChartLegend = chartData.lineChartLegend;
  public lineChartType = chartData.lineChartType;

  public smallLineChartData = chartData.smallLineChartData;
  public smallLineChartLabels = chartData.smallLineChartLabels;
  public smallLineChartOptions = chartData.smallLineChartOptions;
  public smallLineChartLegend = chartData.smallLineChartLegend;
  public smallLineChartType = chartData.smallLineChartType;

  public smallLine2ChartData = chartData.smallLine2ChartData;
  public smallLine2ChartLabels = chartData.smallLine2ChartLabels;
  public smallLine2ChartOptions = chartData.smallLine2ChartOptions;
  public smallLine2ChartLegend = chartData.smallLine2ChartLegend;
  public smallLine2ChartType = chartData.smallLine2ChartType;

  public smallLine3ChartData = chartData.smallLine3ChartData;
  public smallLine3ChartLabels = chartData.smallLine3ChartLabels;
  public smallLine3ChartOptions = chartData.smallLine3ChartOptions;
  public smallLine3ChartLegend = chartData.smallLine3ChartLegend;
  public smallLine3ChartType = chartData.smallLine3ChartType;

  public smallLine4ChartData = chartData.smallLine4ChartData;
  public smallLine4ChartLabels = chartData.smallLine4ChartLabels;
  public smallLine4ChartOptions = chartData.smallLine4ChartOptions;
  public smallLine4ChartColors = chartData.smallLine4ChartColors;
  public smallLine4ChartLegend = chartData.smallLine4ChartLegend;
  public smallLine4ChartType = chartData.smallLine4ChartType;

  public chart3 = chartData.chart3;

  selectedType: any = '2';
  months: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  years: number[] = [];
  selectedMonth: string = '';
  selectedYear: number = 2024;
  startDate!: Date;
  endDate!: Date;

  totalProperty: any = '';
  totalInvestor: any = '';
  totalBooking: any = '';
  totalSales: any = '';
  totalExpenses: any = '';

  totalOperationData: any = '';
  view: any[] = [409, 303];

  revenueByInvestor: { [key: string]: number } = {};
  revenueByProperty: { [key: string]: number } = {};
  revenueByCategory: { [key: string]: number } = {};
  sortedRevenueByProperty: any = [];
  sortedRevenueByInvestor: any = [];
  sortedRevenueByCategory: any = [];

  propertyNames: Map<string, string> = new Map();
  investorNames: Map<string, string> = new Map();
  categoryNames: Map<string, string> = new Map();


  ngOnInit() {
    this.loadPropertyNames();
    this.loadInvestorNames();
    this.loadCategoryNames();
    this.updateResult();
  }

  onTypeChange(type: any) {
    this.selectedType = type;
    if (this.selectedType === '1') {
      this.selectedMonth = '';
    }
    this.updateDates();
  }

  onYearChange(year: number): void {
    this.selectedYear = year;
    this.updateDates();
  }

  onMonthChange(month: string): void {
    this.selectedMonth = month;
    console.log("this.selectedMonth", this.selectedMonth);
    this.updateDates();
  }

  private generateYears(): void {
    const currentYear = new Date().getFullYear();
    this.years = Array.from({ length: 4 }, (_, i) => currentYear - i);
  }

  private updateDates() {
    if (this.selectedType === '1') {
      this.startDate = new Date(this.selectedYear, 0, 1);
      this.endDate = new Date(this.selectedYear + 1, 0, 1);
    }
    else if (this.selectedType === '2' && this.selectedMonth) {
      const monthIndex = this.months.indexOf(this.selectedMonth);
      this.startDate = new Date(this.selectedYear, monthIndex, 1);
      this.endDate = new Date(this.selectedYear, monthIndex + 1, 1);
    }
  }

  search() {
    this.updateResult();
  }

  async updateResult() {
    await this.loadPropertyData();
    await this.loadInvestorData();

   
    // Fetch booking data
    onSnapshot(query(collection(this.afs, "booking"), where('inDate', '>=', this.startDate),
      where('inDate', '<=', this.endDate)), queryRef => {
      const bookingList = queryRef.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      this.totalBooking = bookingList.length;
      console.log('total booking: ', this.totalBooking);
    });

    // Handle invoice and expenses data
    const invoicePromise = this.fetchInvoiceData();
    const expensesPromise = this.fetchExpensesData();

    Promise.all([invoicePromise, expensesPromise]).then(() => {
      this.totalOperationData = [
        { 'name': "Total Profit", 'value': (parseFloat(this.totalSales) - parseFloat(this.totalExpenses)) },
        { 'name': "Total Sales", 'value': this.totalSales },
        { 'name': "Total Expenses", 'value': this.totalExpenses }
      ];

      console.log("this.totalOperationData", this.totalOperationData);
    });
  }

  async loadPropertyData() {
    return new Promise<void>((resolve) => {
      onSnapshot(query(collection(this.afs, "property"), orderBy('created_at', "desc")), queryRef => {
        this.totalProperty = queryRef.docs.length;
        this.propertyList = queryRef.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        console.log('Property List Loaded', this.propertyList);
        resolve();
      });
    });
  }

  async loadInvestorData() {
    return new Promise<void>((resolve) => {
      onSnapshot(query(collection(this.afs, "investor"), orderBy('created_at', "desc")), queryRef => {
        this.totalInvestor = queryRef.docs.length;
        this.investorList = queryRef.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        console.log('Investor List Loaded', this.investorList);
        resolve();
      });
    });
  }

  fetchInvoiceData() {
    return new Promise<void>((resolve) => {
      onSnapshot(query(collection(this.afs, "invoice"), where('issuedDate', '>=', this.startDate),
        where('issuedDate', '<=', this.endDate)), queryRef => {
        const invoiceList: any = queryRef.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        const totalInvoice = invoiceList.reduce((acc, item) => acc + item.total, 0);
        this.totalSales = totalInvoice.toFixed(2);

        this.revenueByInvestor = {};
        this.revenueByProperty = {};
        this.revenueByCategory = {};

        for (const invoice of invoiceList) {
          const propertyId = invoice.propertyId;
          const amount = invoice.total;

          if (this.revenueByProperty[propertyId]) {
            this.revenueByProperty[propertyId] += amount;
          } else {
            this.revenueByProperty[propertyId] = amount;
          }

          const property = this.propertyList.find(prop => prop.id === propertyId);

          if (property) {
            const investorId = property.investor_id;
            const categoryId = property.category;  

            if (this.revenueByInvestor[investorId]) {
              this.revenueByInvestor[investorId] += amount;
            } else {
              this.revenueByInvestor[investorId] = amount;
            }

            if (this.revenueByCategory[categoryId]) {
              this.revenueByCategory[categoryId] += amount;
            } else {
              this.revenueByCategory[categoryId] = amount;
            }
          }
        }

        this.sortedRevenueByProperty = Object.entries(this.revenueByProperty)
          .map(([propertyId, revenue]) => ({ propertyId, revenue }))
          .sort((a, b) => b.revenue - a.revenue).slice(0, 5);

        this.sortedRevenueByInvestor = Object.entries(this.revenueByInvestor)
          .map(([investorId, revenue]) => ({ investorId, revenue }))
          .sort((a, b) => b.revenue - a.revenue).slice(0, 5);

        this.sortedRevenueByCategory = Object.entries(this.revenueByCategory)
          .map(([categoryId, revenue]) => ({ categoryId, revenue }))
          .sort((a, b) => b.revenue - a.revenue).slice(0, 5);

        console.log("this.revenueByCategory", this.revenueByCategory);
        resolve();
      });
    });
  }

  fetchExpensesData() {
    return new Promise<void>((resolve) => {
      onSnapshot(query(collection(this.afs, "expenses"), where('inDate', '>=', this.startDate),
        where('inDate', '<=', this.endDate)), queryRef => {
        const expensesList: any = queryRef.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        const totalExpenses = expensesList.reduce((acc, item) => acc + item.total, 0);
        this.totalExpenses = totalExpenses.toFixed(2);
        resolve();
      });
    });
  }

  loadPropertyNames() {
    this.sortedRevenueByProperty.forEach(property => {
      this.getPropertyName(property.propertyId);
    });
  }

  loadInvestorNames() {
    this.sortedRevenueByInvestor.forEach(investor => {
      this.getInvestorName(investor.investorId);
    });
  }

  loadCategoryNames() {
    this.sortedRevenueByCategory.forEach(category => {
      this.getCategoryName(category.categoryId);
    });
  }

  getPropertyName(propertyId: string) {
    const existingName = this.propertyNames.get(propertyId);
    if (existingName) {
      return existingName; // Return the cached name if it exists
    }

    onSnapshot(doc(this.afs, "property/" + propertyId), queryRef => {
      const data = queryRef.data();
      if (data) {
        const propertyName = data.name ?? 'Unknown Property';
        this.propertyNames.set(propertyId, propertyName);
      }
    });
  }

  getInvestorName(investorId: string) {
    const existingName = this.investorNames.get(investorId);
    if (existingName) {
      return existingName; // Return the cached name if it exists
    }

    onSnapshot(doc(this.afs, "investor/" + investorId), queryRef => {
      const data = queryRef.data();
      if (data) {
        const investorName = data.name ?? 'Unknown investor';
        this.investorNames.set(investorId, investorName);
      }
    });
  }

  getCategoryName(catergoryId: string) {
    const existingName = this.categoryNames.get(catergoryId);
    if (existingName) {
      return existingName;
    }

    onSnapshot(doc(this.afs, "property-category/" + catergoryId), queryRef => {
      const data = queryRef.data();
      if (data) {
        const categoryName = data.name ?? 'Unknown category';
        this.categoryNames.set(catergoryId, categoryName);
      }
    });
  }

  formatCurrency(value: number): string {
    return value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
}
