import {Component, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from 'src/app/shared/service/auth.service';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';

@Component({selector: 'app-login', templateUrl: './login.component.html', styleUrls: ['./login.component.scss']})
export class LoginComponent implements OnInit {

    public active = 1;
    form : FormGroup;
    submitting : boolean = false;

    constructor(private authService : AuthService, private router : Router) {}

    ngOnInit() {
        this.resetForm();
    }

    private resetForm() {
        this.form = new FormGroup({
            email: new FormControl('', [Validators.required]),
            password: new FormControl('', [Validators.required])
        })
    }

    validSubmit(form : FormGroup) {
        form.markAllAsTouched();
        if (!form.valid) {
            return;
        }
        if (this.submitting) {
            return;
        }

        this.submitting = true;
        const data = {
            ...this.form.value
        };

        this
            .authService
            .login(data.email, data.password)
            .then((res : any) => {
                this
                    .router
                    .navigate(['dashboard'])
                    .then(() => {
                        this.submitting = false;
                        this.resetForm();
                    });
            }, (msg : any) => {
                this.submitting = false;
                Swal.fire("Registration", msg, "error");
            });
    }

}
