<!-- Container-fluid starts-->
<div class="container-fluid">

    <div class="row">
        <div class="col-sm-2 mb-4">
            <label for="type">Type</label>
            <ng-select
            (change)="onTypeChange($event)" 
            [(ngModel)]="selectedType" 
            [clearable]="false">
                <ng-option value="1">Yearly</ng-option>
                <ng-option value="2">Monthly</ng-option>
            </ng-select>
        </div>

        <div class="col-sm-2" *ngIf="selectedType === '2'">
            <label for="monthSelect">Select Month:</label>
            <ng-select
                id="monthSelect"
                [(ngModel)]="selectedMonth"
                (change)="onMonthChange($event)"
                [items]="months"
                [clearable]="false"
                placeholder="Select month"></ng-select>
        </div>
        <div class="col-sm-2">
            <label for="yearSelect">Select Year:</label>
            <ng-select
                id="yearSelect"
                [(ngModel)]="selectedYear"
                (ngModelChange)="onYearChange($event)"
                [clearable]="false">
                <ng-option *ngFor="let year of years" [value]="year">{{ year }}</ng-option>
            </ng-select>
        </div>
        <div class="col-sm-2 mb-4 d-flex align-items-end  text-end">
            <button
                class="btn btn-primary"
                (click)="search()">
                Search
            </button>
        </div>
        </div>
    <div class="row">
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden widget-cards">
                <div class="bg-primary card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-3">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'home'" class="font-primary"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-9"><span class="m-0">Total Property</span>
                            <h3 class="mb-0"><span class="counter">{{ totalProperty }}</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden  widget-cards">
                <div class="bg-warning card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-3">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'user'" class="font-warning"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-9"><span class="m-0">Total Owners</span>
                            <h3 class="mb-0"><span class="counter">{{ totalInvestor }}</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden widget-cards">
                <div class="bg-secondary card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-3">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'calendar'" class="font-secondary"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-9"><span class="m-0">Total Bookings</span>
                            <h3 class="mb-0"><span class="counter">{{ totalBooking ? totalBooking : 0 }}</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden widget-cards">
                <div class="bg-danger card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-3">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'dollar-sign'" class="font-danger"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-9"><span class="m-0">Total Profit</span>
                            <h3 class="mb-0">RM <span class="counter">{{ formatCurrency(totalSales - totalExpenses) }}</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Top Investors -->
        <div class="col-xl-4 xl-100">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Top Investors</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table">
                        <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th scope="col">Name </th>
                                    <th scope="col">Revenue (RM)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="sortedRevenueByInvestor && sortedRevenueByInvestor.length <=0">
                                <tr>
                                    <td>No investor...</td>
                                    <td ></td>
                                </tr>
                                </ng-container>
                                <tr *ngFor="let investor of sortedRevenueByInvestor">
                                    <td>{{ getInvestorName(investor.investorId) }}</td>
                                    <td class="digits">{{ formatCurrency(investor.revenue) }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <a href="investor/investor-list" class="btn btn-primary ms-0">View All</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Top Category -->
        <div class="col-xl-4 xl-100">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Top Category</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table">
                        <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th scope="col">Name </th>
                                    <th scope="col">Revenue (RM)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="sortedRevenueByCategory && sortedRevenueByCategory.length <=0">
                                <tr>
                                    <td>No Category...</td>
                                    <td ></td>
                                </tr>
                                </ng-container>
                                <tr *ngFor="let category of sortedRevenueByCategory">
                                    <td>{{ getCategoryName(category.categoryId) }}</td>
                                    <td class="digits">{{ formatCurrency(category.revenue) }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <a href="investor/investor-list" class="btn btn-primary ms-0">View All</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Top Property -->
        <div class="col-xl-4 xl-100">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Top Property</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table">
                        <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th scope="col">Name </th>
                                    <th scope="col">Revenue (RM)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="sortedRevenueByProperty && sortedRevenueByProperty.length <=0">
                                <tr>
                                    <td>No property...</td>
                                    <td ></td>
                                </tr>
                                </ng-container>
                                <tr *ngFor="let property of sortedRevenueByProperty">
                                    <td>{{ getPropertyName(property.propertyId) }}</td>
                                    <td class="digits">{{ formatCurrency(property.revenue) }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <a href="investor/investor-list" class="btn btn-primary ms-0">View All</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-3 xl-100">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Total Income</h5>
                </div>
                <div class="card-body">
                    <div class="chart-block chart-vertical-center">
                        <div style="position: relative; width: 409px; height:300px;"> <!-- Set your desired size -->
                            <ngx-charts-pie-chart class="pie-colours-1" 
                                [view]="view"
                                [scheme]="doughnutChartColorScheme" 
                                [results]="totalOperationData" 
                                [explodeSlices]="false"
                                [labels]="doughnutChartShowLabels" 
                                [arcWidth]="0.35" 
                                [doughnut]="true"
                                [animations]="false" 
                                [tooltipDisabled]="doughnutChartTooltip"
                                [gradient]="doughnutChartGradient">
                            </ngx-charts-pie-chart>
                            
                            <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); text-align: center; color: #000;">
                              <p style="margin-bottom: 0; color: #222;">Total Profit</p>
                              <h3 style="color: #222">{{ formatCurrency(totalSales - totalExpenses) }}</h3>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 xl-100">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Daily Operation</h5>
                </div>
                <div class="card-body">
                </div>
            </div>
        </div>
        <!-- <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Buy / Sell</h5>
                </div>
                <div class="card-body sell-graph">
                    <canvas baseChart id="myGraph" [datasets]="lineChartData" [labels]="lineChartLabels"
                        [options]="lineChartOptions" [legend]="lineChartLegend"
                        [type]="lineChartType" (chartHover)="chartHovered($event)"
                        (chartClick)="chartClicked($event)"></canvas>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Sales Status</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        
                        <div class="col-xl-3 col-sm-6 xl-50">
                            <div class="order-graph sm-order-space overflow-hidden">
                                <h6>Sales By Location</h6>
                                <div class="peity-chart-dashboard text-center chart-vertical-center">
                                    <ngx-charts-pie-chart class="pie-colours-1" [view]="view"
                                        [scheme]="doughnutChartColorScheme" [results]="doughnutData"
                                        [explodeSlices]="false" [labels]="doughnutChartShowLabels" [arcWidth]=0.99
                                        [doughnut]="true" [animations]="false" [tooltipDisabled]="doughnutChartTooltip"
                                        [gradient]="doughnutChartGradient">
                                    </ngx-charts-pie-chart>
                                </div>
                                <div class="order-graph-bottom sales-location">
                                    <div class="media">
                                        <div class="order-shape-primary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 me-0">Germany <span class="pull-right">25%</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-secondary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 me-0">Brasil <span class="pull-right">10%</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-danger"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 me-0">United Kingdom<span class="pull-right">34%</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-warning"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 me-0">Australia<span class="pull-right">5%</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-success"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 me-0">Canada <span class="pull-right">25%</span></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 xl-100">
                            <div class="order-graph xl-space">
                                <h6>Revenue for last month</h6>
                                <div class="ct-4 flot-chart-container">
                                    <x-chartist [configuration]="chart5">
                                    </x-chartist>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

    </div>
</div>
<!-- Container-fluid Ends-->