import { Component, OnInit } from '@angular/core';
import { jsPDF } from "jspdf";
import { Firestore, getDoc, doc, onSnapshot, collection } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
	exporting = false;
	invoice: any;	
	setting: any;
	categoryList: any;

    constructor(private afs: Firestore,
		private aroute: ActivatedRoute
    ) { }
	
	ngOnInit() {		
		const id = this.aroute.snapshot.paramMap.get('id');
		if (id) {
			getDoc(doc(this.afs, "invoice/" + id))
			.then(docRef => {
				this.invoice = docRef.data();
				console.log("invoice", this.invoice)
			});
		} else {
			// redirect? or show blank page?
		}

		getDoc(doc(this.afs, "setting/profile"))
		.then(docRef => {
			this.setting = docRef.data();
		});

		onSnapshot(collection(this.afs, "category"), queryRef => {
			const docs = queryRef.docs;
			this.categoryList = [];
			  for (let i = 0; i < docs.length; i++) {
			  const data: any = docs[i].data();
			  data.id = docs[i].id;
			  this.categoryList.push(data);
			}
		  });
	}
	
	exportPDF() {
		if (this.exporting) {
			return;
		}
		this.exporting = true;
		const target = document.getElementById('pdf_content');

		if(target){
			html2canvas(target).then(canvas => {
				const imgWidth = 210; // A4 width in mm for landscape
				const pageHeight = 297; // A4 height in mm for landscape
				const margin = 8; // Margin in mm
				const imgHeight = canvas.height * imgWidth / canvas.width;
				let heightLeft = imgHeight;
		
				const contentDataURL = canvas.toDataURL('image/png');
				let pdf = new jsPDF('p', 'mm', 'a4');
				let position = margin;
		
				// Add first page
				pdf.addImage(contentDataURL, 'PNG', margin, position, imgWidth - 2 * margin, imgHeight * (imgWidth - 2 * margin) / imgWidth);
				heightLeft -= pageHeight;
		
				// Add additional pages if necessary
				while (heightLeft >= 0) {
				  position = heightLeft - imgHeight;
				  pdf.addPage();
				  pdf.addImage(contentDataURL, 'PNG', margin, position, imgWidth - 2 * margin, imgHeight * (imgWidth - 2 * margin) / imgWidth);
				  heightLeft -= pageHeight;
				}
		
				//pdf.save(this.invoice.name + ".pdf");
		
				// Generate PDF as Blob and open in a new tab
				const pdfOutput = pdf.output('blob');
				const blobUrl = URL.createObjectURL(pdfOutput);
				window.open(blobUrl, '_blank');
			  });
		}
	}
	
	getBalance() {
		return -((this.invoice?.paid || 0) - this.invoice?.total);
	}

	getItemName(id: any){
		const item = this.categoryList?.find((x: any) => x.id == id);
    	return item?.name;
	}
}
