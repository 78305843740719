<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Product Lists</h5>
                </div>
                <div class="card-body">
					<table datatable [dtTrigger]="dtTrigger" class="table table-striped">
						<thead>
							<tr>
								<th>#</th>
								<th data-orderable="false" data-searchable="false">Image</th>
								<th>Product Title</th>
								<th>Empty Type</th>
								<th>Quantity</th>
								<th data-orderable="false" data-searchable="false">Actions</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngIf="digital_categories === undefined">
								<th></th>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr *ngFor="let item of digital_categories">
								<th>{{ item.id }}</th>
								<td>
									<img [src]="item.img" class="imgTable" style="width: 20px" />
								</td>
								<td>{{ item.title }}</td>
								<td>{{ item.entry_type }}</td>
								<td>{{ item.quantity }}</td>
								<td>
									<a href="javascript:void(0)" title="Edit"><i class='fa fa-edit f-12'></i></a>&nbsp;
									<a (click)="delete(item)" title="Delete"><i class="fa fa-trash-o"></i></a> 
								</td>
							</tr>
						</tbody>
					</table>
                </div>
            </div>
        </div>
    </div>
</div>